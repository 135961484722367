import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Nav } from "react-bootstrap";
import Slider from "react-slick";
import style from "./NavigacijaSekcije.module.css";
import { useTranslation } from "react-i18next";

const FakultetNavbar = ({ items, observeRef }) => {
  const [isSticky, setIsSticky] = useState(false);
  const { t } = useTranslation();

  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    arrows: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  useEffect(() => {
    const handleObserver = (entries) => {
      entries.forEach((entry) => {
        if (entry.boundingClientRect.top <= 0 && entry.isIntersecting) {
          setIsSticky(false); // Make unsticky when Onama reaches top while scrolling up
        } else if (entry.boundingClientRect.top <= 0) {
          setIsSticky(true); // Make sticky when Onama leaves the viewport
        }
      });
    };

    const observerOptions = {
      root: null, // Viewport is the root
      threshold: 0.1, // Trigger when the top of observeRef aligns with the top of the viewport
    };

    const observer = new IntersectionObserver(handleObserver, observerOptions);

    const target = observeRef.current;
    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) observer.unobserve(target);
    };
  }, [observeRef]);

  const handleClick = (e, link) => {
    e.preventDefault();

    // Select the target element
    const targetElement = document.querySelector(link);
    if (targetElement) {
      // Get the target element's position relative to the document
      const elementPosition =
        targetElement.getBoundingClientRect().top + window.scrollY;

      // Check if the navbar heights are defined; default to 0 if not
      const navbar = document.querySelector(".homepage__navbar");
      const sectionNavbar = document.querySelector(".section__navbar");

      const navbarHeight = navbar ? navbar.offsetHeight : 0;
      const sectionNavbarHeight = sectionNavbar
        ? sectionNavbar.offsetHeight
        : 0;

      // Adjust the scroll position
      const offsetPosition =
        elementPosition -
        navbarHeight -
        sectionNavbarHeight -
        sectionNavbar.offsetHeight / 3;

      // Smooth scroll to the calculated position
      window.scrollTo({
        top: Math.max(0, offsetPosition), // Ensure we don't scroll to negative values
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      // ref={navbarRef}
      className={`d-flex align-items-center section__navbar ${
        style.sectionNavbar
      } ${isSticky ? style.sticky : ""}`}
    >
      <div className="container">
        {/* For desktop and large screens */}
        <Nav className="d-none d-lg-flex">
          {items.map((item, index) => (
            <Nav.Link
              href={item.link}
              key={index}
              className={style.linkStyle}
              onClick={(e) => handleClick(e, item.link)}
            >
              {t(`${item.nameKey}`)}
            </Nav.Link>
          ))}
        </Nav>

        {/* Slick Slider for mobile */}
        <div className="d-lg-none slider-container">
          <Slider {...settings}>
            {items.map((item, index) => (
              <div
                key={index}
                className="px-2"
                style={{ width: "min-content" }}
              >
                <a
                  href={item.link}
                  className={style.linkStyle}
                  onClick={(e) => handleClick(e, item.link)}
                >
                  {t(`${item.nameKey}`)}
                </a>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default FakultetNavbar;
