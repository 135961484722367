import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Logo from "../assets/mef_logo_white.png";
import {
  FaEnvelope,
  FaLinkedin,
  FaPhone,
  FaMapMarkerAlt,
} from "react-icons/fa";
import "../styles/global.css";
import style from "./Footer.module.css";
import { useTranslation } from "react-i18next";

const currentYear = new Date().getFullYear();

const Footer = () => {
  const location = useLocation();
  const isMobile = window.innerWidth < 1000;
  const navigate = useNavigate();
  const language = localStorage.getItem("language");
  const showFooterNavigation =
    (location.pathname === "/" || location.pathname === "/en/") && !isMobile;

  const { t } = useTranslation();

  return (
    <footer class="bg-transparent text-white py-5 pb-2" id="footer">
      <Container>
        {/* First Section */}
        <Row className="pb-4 mb-4 ">
          <Col md={5} xs={12} className="mb-4 mb-md-0">
            <div className="d-flex align-items-center">
              <img
                src={Logo}
                alt="Logo"
                class="me-3"
                style={{ width: "100px" }}
              />
              <div>
                <h5 class={style.universityTitle}>
                  {t("navbar.nazivUniverziteta")}
                </h5>
                <h5 class={style.universityTitle}>
                  {t("navbar.nazivFakulteta")}
                </h5>
              </div>
            </div>
            <p style={{ fontSize: "14px" }} className="mt-4 mb-1">
              {t("navbar.fakultetClanicaUNSATekst")}
            </p>
          </Col>
          <Col md={7} xs={12}>
            <h5 className="mb-4" class={style.sectionTitle}>
              {t("navbar.kontaktInformacije")}
            </h5>
            <Row>
              <Col xs={12} md={4} className="mb-2">
                <p class="fs-6">
                  <FaEnvelope /> info@mef.unsa.ba
                </p>
                <p
                  style={{ fontSize: "16px", cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/school/mef-unsa/posts/?feedView=all",
                      "_blank"
                    )
                  }
                >
                  <FaLinkedin /> LinkedIn
                </p>
              </Col>
              <Col xs={12} md={4} className="mb-2">
                <p class="fs-6">
                  <FaPhone /> +387 33 729 800
                </p>
                <p class="fs-6">
                  <FaPhone /> +387 33 653 055
                </p>
              </Col>
              <Col xs={12} md={4} className="mb-2">
                <p class="fs-6">
                  <FaMapMarkerAlt /> Vilsonovo šetalište 9
                </p>
                <p class="fs-6">
                  <FaMapMarkerAlt /> 71000 Sarajevo
                </p>
                <p class="fs-6">
                  <FaMapMarkerAlt /> Bosna i Hercegovina
                </p>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Second and Third Sections */}
        {showFooterNavigation ? (
          <div className="footer__navigation border-top border-bottom border-secondary pt-4 ">
            <Row className="">
              <Col xs={6} md={3} className="mb-3">
                <h5 class={style.sectionTitle}>{t("navbar.fakultet")}</h5>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li className="footer__link">
                    <Link to="/">{t("navbar.pocetna")}</Link>
                  </li>
                  <li className="footer__link">
                    <Link to="/fakultet">{t("navbar.oNama")}</Link>
                  </li>
                  <li className="footer__link">
                    <Link to="/fakultet/nastavno-osoblje">
                      {t("navbar.nastavnoOsoblje")}
                    </Link>
                  </li>
                  <li className="footer__link">
                    <Link to="/fakultet/dokumenti">
                      {t("navbar.dokumenti")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/fakultet/kontakt">{t("navbar.kontakt")}</Link>
                  </li>
                </ul>

                <h5 class={style.sectionTitle}>
                  {t("navbar.studentiHeading")}
                </h5>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li className="footer__link">
                    <Link to="/coomingsoon">{t("navbar.rezultatiIspita")}</Link>
                  </li>
                  <li className="footer__link">
                    <Link to="/fakultet/studentska-sluzba">
                      {t("navbar.studentskaSluzba")}
                    </Link>
                  </li>
                  <li className="footer__link">
                    <Link to="/coomingsoon">{t("navbar.studentskaUnija")}</Link>
                  </li>
                  <li className="footer__link">
                    <Link to="/fakultet/biblioteka">
                      {t("navbar.biblioteka")}
                    </Link>
                  </li>
                </ul>
              </Col>

              <Col xs={6} md={3} className="mb-3">
                <h5 class={style.sectionTitle}>{t("navbar.ciklusi")}</h5>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li className="footer__link">
                    <Link to="/nastava/prvi-ciklus-studija">
                      {t("navbar.prviCiklus")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/nastava/drugi-ciklus-studija">
                      {t("navbar.drugiCiklus")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/nastava/treci-ciklus-studija">
                      {t("navbar.treciCiklus")}
                    </Link>
                  </li>
                </ul>
                <h5 class={style.sectionTitle}>{t("navbar.aktuelnosti")}</h5>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li className="footer__link">
                    <Link to="/aktuelnosti/obavijesti">
                      {t("navbar.obavijesti")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/aktuelnosti/novosti">{t("navbar.novosti")}</Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/coomingsoon">{t("navbar.seminari")}</Link>
                  </li>

                  <li className="footer__link">
                    {" "}
                    <Link to="/coomingsoon">{t("navbar.konferencije")}</Link>
                  </li>

                  <li className="footer__link">
                    {" "}
                    <Link to="/coomingsoon">{t("navbar.masinijada")}</Link>
                  </li>

                  <li className="footer__link">
                    {" "}
                    <Link to="/coomingsoon">{t("navbar.studenti")}</Link>
                  </li>

                  <li className="footer__link">
                    {" "}
                    <Link to="/coomingsoon">{t("navbar.fondovi")}</Link>
                  </li>
                </ul>
              </Col>

              <Col xs={6} md={3} className="mb-3">
                <h5 class={style.sectionTitle}>{t("navbar.projekti")}</h5>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li className="footer__link">
                    <Link to="/coomingsoon">{t("navbar.oProjektima")}</Link>
                  </li>
                  <li className="footer__link">
                    <Link to="/coomingsoon">{t("navbar.projektRECDI")}</Link>
                  </li>
                </ul>

                <h5 class={style.sectionTitle}>{t("navbar.odsjeci")}</h5>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li className="footer__link">
                    <Link to="/odsjeci/katedra-za-matematiku-i-fiziku">
                      {t("navbar.fizikaMatematika")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/odsjeci/katedra-za-mehaniku">
                      {t("navbar.mehanika")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/odsjeci/odsjek-za-energetiku-procesnu-tehniku-i-okolinsko-inzinjerstvo">
                      {t("navbar.energetikaOkolinsko")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/odsjeci/odsjek-za-industrijsko-inzinjerstvo-i-menadzment">
                      {t("navbar.industrijskoMenadzment")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/odsjeci/odsjek-za-masinske-konstrukcije">
                      {t("navbar.masinskeKonstrukcije")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/odsjeci/odsjek-za-masinski-proizvodni-inzinjering">
                      {t("navbar.masinskiProizvodni")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/odsjeci/odsjek-za-motore-i-vozila">
                      {t("navbar.motoriVozila")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/odsjeci/odsjek-za-odbrambene-tehnologije">
                      {t("navbar.odbrambeneTehnologije")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/odsjeci/odsjek-za-tehnologiju-drveta">
                      {t("navbar.tehnologijaDrveta")}
                    </Link>
                  </li>
                </ul>
              </Col>

              <Col xs={6} md={3} className="mb-3">
                <h5 class={style.sectionTitle}>{t("navbar.linkovi")}</h5>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li className="footer__link">
                    <a
                      href="https://www.eunsa.ba/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      eUNSA
                    </a>
                  </li>

                  <li className="footer__link">
                    <a
                      href="https://biblioteka.mef.unsa.ba/default"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      e-Biblioteka
                    </a>
                  </li>

                  <li className="footer__link">
                    <a
                      href="https://webmail.mef.unsa.ba/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Web Mail
                    </a>
                  </li>

                  <li className="footer__link">
                    <a
                      href="https://nastava2.mef.unsa.ba/moodle/login/index.php"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Courseware
                    </a>
                  </li>
                </ul>

                <h5 class={style.sectionTitle}>{t("navbar.ostalo")}</h5>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li className="footer__link">
                    <a
                      href="https://unsa.ba/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("navbar.nazivUniverziteta")}
                    </a>
                  </li>

                  <li className="footer__link">
                    <a
                      href="https://mo.ks.gov.ba/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("navbar.ministarstvoObrazovanjaKS")}
                    </a>
                  </li>

                  <li className="footer__link">
                    <a
                      href="http://www.fmon.gov.ba/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("navbar.ministarstvoObrazovanjaFBiH")}
                    </a>
                  </li>

                  <li className="footer__link">
                    <a
                      href="https://www.anticorrupiks.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("navbar.prijavaKorupcije")}
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
            {/* Second and Third Sections */}
          </div>
        ) : (
          <div
            className="container border-bottom border-top border-secondary py-4 mb-3"
            style={{ borderColor: "#DDDDDD" }}
          >
            <div className="row d-flex justify-content-between align-items-center">
              <div className="col-12 col-md-auto mb-2 mb-md-0">
                <Link
                  to="/"
                  className="text-decoration-none text-white footer__link btn-link"
                >
                  {t("navbar.pocetna")}
                </Link>
              </div>

              <div className="col-12 col-md-auto mb-2 mb-md-0">
                <Link
                  to="/fakultet"
                  className={`${style.footerLinkStyle} text-decoration-none text-white footer__link btn-link`}
                >
                  {t("navbar.oNama")}
                </Link>
              </div>

              <div className="col-12 col-md-auto mb-2 mb-md-0">
                <Link
                  to="/aktuelnosti/novosti"
                  className={`${style.footerLinkStyle} text-decoration-none text-white footer__link btn-link`}
                >
                  {t("navbar.novosti")}
                </Link>
              </div>

              <div className="col-12 col-md-auto mb-2 mb-md-0">
                <Link
                  to="/aktuelnosti/obavijesti"
                  className={`${style.footerLinkStyle} text-decoration-none text-white footer__link btn-link`}
                >
                  {t("navbar.obavijesti")}
                </Link>
              </div>

              <div className="col-12 col-md-auto mb-2 mb-md-0">
                <Link
                  to="/fakultet/nastavno-osoblje"
                  className={`${style.footerLinkStyle} text-decoration-none text-white footer__link btn-link`}
                >
                  {t("navbar.nastavnoOsoblje")}
                </Link>
              </div>

              <div className="col-12 col-md-auto mb-2 mb-md-0">
                <Link
                  to="/fakultet/dokumenti"
                  className={`${style.footerLinkStyle} text-decoration-none text-white footer__link btn-link`}
                >
                  {t("navbar.dokumenti")}
                </Link>
              </div>
            </div>
          </div>
        )}
      </Container>
      <p className={`${style.rightsText} text-center mt-4`}>
        © {currentYear} MEF All rights reserved
      </p>
    </footer>
  );
};

export default Footer;
